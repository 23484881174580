import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_WNavDropdownItem = _resolveComponent("WNavDropdownItem")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, { class: "relative" }, {
    default: _withCtx(({ open }) => [
      _createVNode(_component_PopoverButton, {
        class: _normalizeClass(open ? _ctx.popoverButtonActiveClass : _ctx.popoverButtonInactiveClass)
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_ChevronDownIcon, {
            class: _normalizeClass([open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']),
            "aria-hidden": "true"
          }, null, 8, ["class"])
        ]),
        _: 2
      }, 1032, ["class"]),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-200",
        "enter-from-class": "opacity-0 translate-y-1",
        "enter-to-class": "opacity-100 translate-y-0",
        "leave-active-class": "transition ease-in duration-150",
        "leave-from-class": "opacity-100 translate-y-0",
        "leave-to-class": "opacity-0 translate-y-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PopoverPanel, { class: "absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.popoverItemTheme)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (item) => {
                    return (_openBlock(), _createBlock(_component_WNavDropdownItem, {
                      key: item.name,
                      nav: item
                    }, null, 8, ["nav"]))
                  }), 128))
                ], 2)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}