

import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { PlusIcon,  ChartBarIcon, GlobeEuropeAfricaIcon,CloudArrowUpIcon } from '@heroicons/vue/20/solid'
import { Bars3Icon, XMarkIcon,   } from '@heroicons/vue/24/outline'
import WNavButton from "@/controls/WNavButton.vue"
import WNavDropdown from "@/controls/WNavDropdown.vue"
import { defineComponent, reactive } from 'vue';
import { useStore } from '@/stores/store'
import { mapActions, mapGetters, mapState } from 'vuex'
import Loader from './components/Loader.vue'


import { useAuth0, AuthState } from "./utils/useAuth0";
const { login, logout, initAuth } = useAuth0(AuthState);


export default defineComponent({
  name: 'App',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    WNavDropdown,
    WNavButton,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    PlusIcon,
    XMarkIcon,
    GlobeEuropeAfricaIcon,CloudArrowUpIcon,
    Loader,
    },
  setup() {
    initAuth();
    const store =  useStore();

    function signOut(): void {
      store.dispatch("signOut");
    }

    function setUser(authUser: Object): void {
      store.dispatch("setUser", authUser);
    }

    let navigation = [
      { name: 'Home', href: '/', current: false , lgHidden: false},
      // { name: 'Embark', href: '/embark', current: false , lgHidden: false},
      // { name: 'Data', href: '/data', current: false , lgHidden: false},
      // { name: 'About Me', href:'/about-me', current: false, lgHidden: false },
      // { name: 'Family', href: '/family', current: false, lgHidden: false },
      { name: 'Projects', href: '/projects', current: false, lgHidden: true },
    ]

    let projectsDropDown = {
      title: "Projects",
      navs: [
        { 
          name: 'Stocks', 
          href: '/stocks', 
          description: "A simple stock screener", 
          icon: ChartBarIcon
        },
        { 
          name: 'Embark', 
          href: '/embark', 
          description: "A travel research & planning tool", 
          icon: GlobeEuropeAfricaIcon
        },
        { 
          name: 'Data', 
          href: '/data', 
          description: "Data crawling, annotating and ML", 
          icon: CloudArrowUpIcon
        }
      ]
    }

    
    
    // const { login, logout, auth0, user, isAuthenticated, isLoading, getAccessTokenSilently, idTokenClaims } =  useAuth0();


    const userNavigation = [
      { name: 'Your Profile', href: '/profile' },
      { name: 'Settings', href: '#' },
      { name: 'Sign out', href: '#', action: async ()=>{
        await logout();
        signOut();
      } },
    ]
    return {
      login,
      logout,
      AuthState,
      setUser,
      projectsDropDown,
      navigation,
      userNavigation
    }
  },
  data(){
    return {
      showSignInModal: false,
      
    }
  },
  computed: {
    ...mapGetters([
      "isInSession",
      "initials"
      
    ]),
    ...mapState([
      "user",
      "loading"
    ]),
  },
  methods:{
    ...mapActions([
      "signOut"
    ]),
  },

});
