import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { IApiRequest, IApiResponse } from "@/common/apiDataModel";
import { BACKEND_BASE_URL, BACKEND_P_BASE_URL } from "../common/appconstants";
import {store} from "@/stores/store";
import { saveAs } from 'file-saver';


export const post = async function Post<Req extends IApiRequest, Res extends IApiResponse>(
  model: Req,
  api: string,
  config?: AxiosRequestConfig,
): Promise<Res>{
  store.commit('toggleLoader', true)
  const url = `${BACKEND_BASE_URL}/${api}`;
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.user.accessToken}`;
  model.idToken = store.state.user.idToken;
  const res = await axios
    .post(url, model, config)
    .then(responseFunc)
    .catch(handleError)
    .finally(() => {
      store.commit('toggleLoader', false)
    })
  return res;
}


export const postp = async function Post<Req extends IApiRequest, Res extends IApiResponse>(
  model: Req,
  api: string,
  config?: AxiosRequestConfig,
  loader?: boolean
): Promise<Res>{
  if (typeof loader == 'undefined' || loader === true) {
    store.commit('toggleLoader', true)
  }
  
  const url = `${BACKEND_P_BASE_URL}/${api}`;
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.user.accessToken}`;
  model.idToken = store.state.user.idToken;
  const res = await axios
    .post(url, model, config)
    .then(responseFunc)
    .catch(handleError)
    .finally(() => {
      if (typeof loader == 'undefined' || loader === true) {
        store.commit('toggleLoader', false)
      }
    })
  return res;
}

export const postpForm = async function Post(
  api: string,
  file: File,
  config?: AxiosRequestConfig,
): Promise<void>{
  store.commit('toggleLoader', true)
  const url = `${BACKEND_P_BASE_URL}/${api}`;

  let formData = new FormData();
  formData.append('file', file);
  
  const res = await axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${store.state.user.accessToken}`
      },
      responseType: 'blob'
    })
    .then(response => {
      saveAs(response.data);
    })
    .catch(handleError)
    .finally(() => {
      store.commit('toggleLoader', false)
    })
}


export const getExternal = async function Get<Res>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<Res>{
  
  const res = await axios
    .get(url, config)
    .then(responseFunc)
    .catch((e) => {
      console.log(e.headers);
    })
  return res;
}



export const handleError: (error: any) => IApiResponse = (error) => {
  console.log(error);
  return {
    responseStatus: error.response.status
  } as IApiResponse;
}

const responseFunc = (response: AxiosResponse<any>): any => {
  return response.data
};
