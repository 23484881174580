
import { useStore } from "vuex"
import { key } from "@/stores/store"
import { defineComponent } from 'vue';

const titleLightStyle = "text-base font-medium text-gray-900";
const titleDarkStyle = "text-base font-medium text-white";
const descriptionlightStyle = "mt-1 text-sm text-gray-500";
const descriptionDarkStyle = "text-sm font-medium text-gray-400";


export default defineComponent({
  props: {
    nav: {
      type: Object,
      required: true
    }
  },
  setup() {
    const store = useStore(key);
    return {
      darkmode: store.state.darkmode,
    }
  },
  computed: {
    titleClass() {
      return this.darkmode ? titleDarkStyle : titleLightStyle;
    },
    descClass() {
      return this.darkmode ? descriptionDarkStyle : descriptionlightStyle;
    },
    anchorClass() {
      return this.darkmode ? '-m-3 p-3 flex items-start rounded-lg hover:bg-gray-700 hover:text-white':'-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'
    }
  }
})
