import createAuth0Client from '@auth0/auth0-spa-js';
import { reactive } from 'vue';
import {store} from "@/stores/store";

export const AuthState = reactive({
    user: null,
    loading: false,
    isAuthenticated: false,
    auth0: null,
    accessToken: null,
    idToken: null
});


const config = {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    client_id:  process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE
};

export const useAuth0 = (state: any) => {
    const handleStateChange = async () => {
        state.isAuthenticated = !!(await state.auth0.isAuthenticated());
        state.user = await state.auth0.getUser();
        state.accessToken = await state.auth0.getTokenSilently();
        state.idToken = (await state.auth0.getIdTokenClaims())["__raw"];
        state.loading = false;
        store.dispatch("setUser", {
            user: state.user,
            accessToken: state.accessToken, 
            idToken: state.idToken
        })
    }

    const initAuth = () => {
        state.loading = true;
        createAuth0Client({
             domain: config.domain,
             client_id: config.client_id,
             cacheLocation: 'localstorage',
             audience: config.audience,
             redirect_uri: window.location.origin
         }).then(async auth => {
             state.auth0 = auth;
             await handleStateChange();
         });        
   }

   const login = async () => {
        await state.auth0.loginWithPopup();
        await handleStateChange();
    };

    const logout = async () => {
        state.auth0.logout({
              returnTo: window.location.origin,
        });
    }
    
    return {
        login,
        logout,
        initAuth
    }
}