import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.nav.href,
    class: _normalizeClass(_ctx.anchorClass)
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.nav.icon), {
      class: "flex-shrink-0 h-6 w-6 text-indigo-600",
      "aria-hidden": "true"
    })),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.titleClass)
      }, _toDisplayString(_ctx.nav.name), 3),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.descClass)
      }, _toDisplayString(_ctx.nav.description), 3)
    ])
  ], 10, _hoisted_1))
}