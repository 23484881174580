
import { useStore } from "vuex"
import { key } from "@/stores/store"
import { defineComponent } from 'vue';


const btnDarkThemeActive = "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium";
const btnDarkThemeInactive = "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
const btnLightThemeActive = "bg-gray-100"
const btnLightThemeInactive = "block py-2 px-4 text-sm text-gray-700"

export default defineComponent({
  name: "WNavButton",
  props: {
    title: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore(key);
    return {
      darkmode: store.state.darkmode,
    }
  },
  computed: {
    activeClass() {
      return this.darkmode ? btnDarkThemeActive : btnLightThemeActive;
    },
    inactiveClass() {
      return this.darkmode ? btnDarkThemeInactive : btnLightThemeInactive;
    }
  }
});
