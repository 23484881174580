
  import { defineComponent } from 'vue';
  import ProjectsDetails from '@/components/ProjectsDetails.vue';

  const certree = {
    name: 'Certree',
    icon: require('@/assets/certree.png')
  }

  const ey = {
    name: 'Certree',
    icon: require('@/assets/EY.png')
  }

  const kayak = {
    name: 'Certree',
    icon: require('@/assets/kayak.png')
  }


  const tools = [
    { 
      name: 'ClickUp',
      icon: require('@/assets/tool_icons/clickup.png')
    },
    { 
      name: 'Jira',
      icon: require('@/assets/tool_icons/jira.png')
    },
    { 
      name: 'Microsoft Office',
      icon: require('@/assets/tool_icons/msoffice.png')
    },
    { 
      name: 'Figma',
      icon: require('@/assets/tool_icons/figma.png')
    },
    { 
      name: 'Sketch',
      icon: require('@/assets/tool_icons/sketch.png')
    },
    { 
      name: 'Slack',
      icon: require('@/assets/tool_icons/slack.png')
    },
    { 
      name: 'AWS',
      icon: require('@/assets/tool_icons/aws.png')
    },
    { 
      name: 'Microsoft Azure',
      icon: require('@/assets/tool_icons/azure.png')
    },
    { 
      name: 'VueJS',
      icon: require('@/assets/tool_icons/vuejs.png')
    },
    { 
      name: 'C#',
      icon: require('@/assets/tool_icons/c_sharp.png')
    },
    { 
      name: 'Python',
      icon: require('@/assets/tool_icons/python.png')
    },
    { 
      name: 'SQL',
      icon: require('@/assets/tool_icons/sql.png')
    },
    { 
      name: 'Git',
      icon: require('@/assets/tool_icons/git.png')
    },
    { 
      name: 'ELK Stack',
      icon: require('@/assets/tool_icons/elk.svg')
    },
    { 
      name: 'HTML, CSS, Javascript',
      icon: require('@/assets/tool_icons/html_css_js.png')
    },
    { 
      name: 'Java',
      icon: require('@/assets/tool_icons/java.png')
    }
  ]
  
  export default defineComponent({
    name: 'home',
    components: {
      ProjectsDetails
    },
    setup() {
      return {
        tools,
        certree,
        ey,
        kayak
      }
    },
  });
  