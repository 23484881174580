import { IGetPlaceDetailsResponse, IGetPlaceDetailsRequest, ITripPlan, ISaveTripResponse, ISaveTripRequest, IGetTripPlansByUserRequest, IGetTripPlansByUserResponse, IGetTripPlanByIdResponse, IGetTripPlanByIdRequest, IDeletePlanRequest, IDeletePlanResonse, IGetPlacesDetailsRequest, IGetPlacesDetailsResponse, IGetSuggestedTravelPlansResponse, IGetSuggestedTravelPlansRequest} from "@/common/apiDataModel";
import { post, getExternal } from "@/services/axiosService"

const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;


export const getPlaceDetails:  (placeId: string) => Promise<IGetPlaceDetailsResponse> = async(placeId) => {
    const req = {
        placeId: placeId
    } as IGetPlaceDetailsRequest
    return await post<IGetPlaceDetailsRequest, IGetPlaceDetailsResponse>(req, 'getPlaceDetails');
};

export const getPlacesDetails:  (placeIds: string[]) => Promise<IGetPlacesDetailsResponse> = async(placeIds) => {
    const req = {
        placeIds
    } as IGetPlacesDetailsRequest
    return await post<IGetPlacesDetailsRequest, IGetPlacesDetailsResponse>(req, 'getPlacesDetails');
};

export const saveTripPlan: (trip: ITripPlan) => Promise<ISaveTripResponse> =  async(trip) => {
    const req = {
        body: trip
    } as ISaveTripRequest
    return await post<ISaveTripRequest, ISaveTripResponse>(req, 'saveTrip');
};

export const getTripPlansByUser: () => Promise<IGetTripPlansByUserResponse> = async() => {
    const req = {} as IGetTripPlansByUserRequest
    return await  post<IGetTripPlansByUserRequest, IGetTripPlansByUserResponse>(req, 'getTripPlansByUser');
}

export const getTripPlanById: (planId: string) => Promise<IGetTripPlanByIdResponse> = async(planId) => {
    const req: IGetTripPlanByIdRequest = { planId };
    return await post<IGetTripPlanByIdRequest, IGetTripPlanByIdResponse>(req, 'getTripPlan');
}

export const deletePlan: (planId: string) => Promise<IDeletePlanResonse> = async(planId) => {
    const req: IDeletePlanRequest = { planId };
    return await post<IDeletePlanRequest, IDeletePlanResonse>(req, 'deletePlan');
}

export const getSuggestedTravelPlans: () => Promise<IGetSuggestedTravelPlansResponse> = async () => {
    return await post<IGetSuggestedTravelPlansRequest, IGetSuggestedTravelPlansResponse>({}, 'getSuggestedTravelPlans')
}