import { IFetchProfileResponse, IGooglePlaceModel, ISaveTripResponse, ITripDay, ITripPlan, IUserModel } from '@/common/apiDataModel';
import { fetchProfile } from "@/services/profileService"
import { computed, InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { saveTripPlan } from '@/services/travelService';


export interface State {
  darkmode: boolean,
  user: IUserModel,
  tripPlan: ITripPlan,
  loading: boolean,
}
export const key: InjectionKey<Store<State>> = Symbol()

export function useStore() {
  return baseUseStore(key);
}


const getNewTripday = () : ITripDay =>{
  return {description: '', placesToVisit: [],attractions: []}
} 

export const store = createStore<State>({
  state: {
    darkmode: true,
    user: {
      // firstName: "William",
      // lastName: "Xu",
      // email: "willyuanxu@gmail.com"
    } as IUserModel,
    tripPlan: {
      tripName: "",
      tripDescription: "",
      tripDetails: [{description: '', placesToVisit: [],attractions: []} as ITripDay] as ITripDay[]
    },
    loading:false
  },
  mutations: {
    setDarkMode(state: State) {
      state.darkmode = true;
    },
    setLightMode(state: State) {
      state.darkmode = false;
    },
    signOut(state) {
      state.user = {} as IUserModel;
    },
    setUser(state, userInfo) {
      state.user.email = userInfo.user.email;
      state.user.firstName = userInfo.user.given_name;
      state.user.lastName = userInfo.user.family_name;
      state.user.accessToken = userInfo.accessToken;
      state.user.idToken = userInfo.idToken;
    },
    addPlanPlaceToVisit(state: State, payload: any) {
    /*
    payload structure 
    {
      place: IGooglePlaceModel,
      index: number
    }
    */
      state.tripPlan.tripDetails[payload.index].placesToVisit.push(payload.place);
    },
    addPlanDay(state: State, dayIndex: number) {
      state.tripPlan.tripDetails.splice(dayIndex+1, 0, getNewTripday())
    },
    removePlanDay(state: State, dayIndex: number) {
      state.tripPlan.tripDetails.splice(dayIndex, 1);
    },
    toggleLoader(state: State, value: boolean) {
      state.loading = value;
    },
    addAttraction(state: State, payload: any) {
      /*
      payload structure 
      {
        place: IUserVisitDestination
        index: number
      */
     state.tripPlan.tripDetails[payload.index].attractions.push({
      ...payload.place,
      notes: ""
     })
    },
    removeAttraction(state: State, payload: any) {
       /*
      payload structure 
      {
        place: IUserVisitDestination
        index: number 
      */
     let removeIndex = -1;
     for (let i = 0; i <  state.tripPlan.tripDetails[payload.index].attractions.length; i++){
        if (state.tripPlan.tripDetails[payload.index].attractions[i].place_id == payload.place.place_id){
          removeIndex = i;
        }
     }
     if (removeIndex > -1) {
        state.tripPlan.tripDetails[payload.index].attractions.splice(removeIndex, 1);
     }
    }

  },
  getters: {
    isInSession(state: State) {
      return Object.keys(state.user).length != 0;
    },
    initials(state: State){
      if(state.user.email!=null){
        return state.user.firstName[0].toUpperCase() + state.user.lastName[0].toUpperCase();
      }
    }

  },
  actions: {
    signOut(context) {
      context.commit("signOut");
    },
    addPlanPlaceToVisit(context, payload: IGooglePlaceModel) {
      context.commit("addPlanPlaceToVisit", payload);
    },

    setUser(context, userInfo){
      context.commit("setUser", userInfo);
      if (userInfo.user.accessToken != null) {
      }
    },
    async fetchProfile(context) : Promise<IFetchProfileResponse> {
     const res: IFetchProfileResponse = await fetchProfile({});
     return res;
    },
    async saveTrip (context) : Promise<ISaveTripResponse> {
      const tripPlan = context.state.tripPlan;
      const res = await saveTripPlan(tripPlan)
      return res;
    }
  }
})
