

import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { Popover, PopoverButton,  PopoverPanel } from '@headlessui/vue'
import WNavDropdownItem from "@/controls/WNavDropdownItem.vue"
import { key } from "@/stores/store"
import { useStore } from "vuex"
import { defineComponent } from 'vue';
const popoverButtonLightThemeActive = "text-gray-900 group bg-white rounded-md inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
const popoverButtonLightThemeInactive = "text-gray-500 group bg-white rounded-md inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
const popoverButtonDarkThemeActive = "px-3 py-2 group rounded-md inline-flex items-center text-sm font-medium text-white  bg-gray-800";
const popoverButtonDarkThemeInactive = "px-3 py-2 group rounded-md inline-flex items-center text-sm font-medium text-gray-300  bg-gray-800";

export default defineComponent({
  name: 'WNavDropdown',
  props: {
    title: {
      type: String,
      required: true
    },
    navs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const store = useStore(key);
    return {
      darkmode: store.state.darkmode,
    }
  },
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    WNavDropdownItem
  },
  computed: {
    popoverButtonActiveClass() {
      return this.darkmode ? popoverButtonDarkThemeActive : popoverButtonLightThemeActive;
    },
    popoverButtonInactiveClass() {
      return this.darkmode ? popoverButtonDarkThemeInactive : popoverButtonLightThemeInactive;
    },
    popoverItemTheme(){
       return this.darkmode ? "bg-gray-800 relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8" : "bg-white relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8"
    }
  }
})

