import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import {store, key} from './stores/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createAuth0 } from '@auth0/auth0-vue'

// import global css file
import "@/global.css"

const AUTH_0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN;
const AUTH_0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID;
const AUTH_0_AUDIENCE = process.env.VUE_APP_AUTH0_AUDIENCE;

const app = createApp(App)
  app
  .use(router)
  .use(store, key)
  .use(VueAxios, axios)
  .mount('#app')
  // .use(
  //   createAuth0({
  //     domain: AUTH_0_DOMAIN,
  //     client_id: AUTH_0_CLIENT_ID,
  //     redirect_uri: window.location.origin,
  //     audience: AUTH_0_AUDIENCE
  //   })
  // )

