import { createRouter, createWebHistory} from "vue-router"
import Home from "@/views/Home.vue"


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // { 
  //   path: '/about-me', 
  //   name: 'AboutMe',
  //   component: () => import("../views/AboutMe.vue")
  // },
  { 
    path: '/projects', 
    name: 'Projects',
    component: () => import("../views/Projects.vue")
  },
  { 
    path: '/stocks', 
    name: 'Stocks',
    component: () => import("../views/Stocks.vue")
  },
  {
    path: '/stocks/lists/:listId',
    name: 'StockList',
    component: () => import("../views/StockList.vue"),
    meta: {title: "Stocks | List"}
  },
  { 
    path: '/embark', 
    name: 'Embark',
    component: () => import("../views/Embark.vue"),
    meta: {title: "Embark"}
  },
  // { 
  //   path: '/family', 
  //   name: 'Family',
  //   component: () => import("../views/Family.vue")
  // },
  { 
    path: '/profile', 
    name: 'Profile',
    component: () => import("../views/Profile.vue"),
    meta: {title: "Profile"}
  },
  { 
    path: '/targa', 
    name: 'Targa',
    component: () => import("../views/Targa.vue"),
    meta: {title: "Targa"}
  },
  { 
    path: '/data', 
    name: 'Data',
    component: () => import("../views/DataCrawler.vue"),
    meta: {title: "Data"}
  },
  { 
    path: '/models', 
    name: 'Models',
    component: () => import("../views/Models.vue"),
    meta: {title: "Models"}
  },
  { 
    path: '/embark/add-plan', 
    name: 'AddPlan',
    component: () => import("../views/AddPlan.vue"),
    meta: {title: "Embark | Add Plan"}
  },
  {
    path: '/embark/plans/:planId',
    name: 'TripPlan',
    component: () => import("../views/Plan.vue"),
    meta: {title: "Embark | Plan Details"}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = "William Xu";


router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE as any
  next()
})


export default router;